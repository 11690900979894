
const Recuperacion = () => {
    return (
        <>
        <div className="recover">
            <h1>Has perdido la contraseña?</h1>
            <h2>Para recuperar la contraseña...</h2>
            <button onClick="/">Soporte</button>
        </div>
        </>
    )
}
export default Recuperacion